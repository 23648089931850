import axios from 'axios';
import { Box, DayPicker, ScheduleList, SectionTitle } from 'components';
import { ScheduleItem } from 'models';
import { useRouter } from 'next/router';
import React from 'react';
import { Container } from 'react-grid-system';
import { getDateFromQuery, getTodayDateString } from 'utils/date';
import { MobileContext } from 'utils/mobileContext';
// import styled from 'styled-components';

export interface ISchedule {
  schedule: ScheduleItem[];
}

export const Schedule: React.FC<ISchedule> = ({ schedule }) => {
  const { query } = useRouter();
  const currentDate = getDateFromQuery(query.date);
  const { isMobile, isTablet } = React.useContext(MobileContext);
  const [data, setData] = React.useState(schedule);

  React.useEffect(() => {
    if (query.date) {
      axios
        .get(`/api/schedule?date=${currentDate}`)
        .then(({ data }) => setData(data));
    }
  }, [query.date]);

  return (
    <Box py={4} as="article">
      <Container fluid md>
        <SectionTitle
          borderColor="main.primary"
          borderWidth={5}
          shadow={!isMobile}
          shadowOffset={4}
          sideText={getTodayDateString(currentDate)}
        >
          Ramówka
        </SectionTitle>
        <Box mt={4}>
          <DayPicker range={isTablet ? 4 : 6} />
        </Box>
        <ScheduleList
          schedule={data}
          highlight={query.highlight}
          currentStation={query.current}
        />
      </Container>
    </Box>
  );
};
