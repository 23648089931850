import { useRouter } from 'next/router';
import React from 'react';

export const usePaginatedList = ({
  itemPages,
  items,
  id,
}: {
  itemPages: number;
  items: unknown[];
  id: string;
}) => {
  const router = useRouter();

  const [isLoading, setLoading] = React.useState(true);
  const pageNum = router.query[id]
    ? parseInt(router.query[id] as string, 10)
    : 1;

  React.useEffect(() => {
    if (items) setLoading(false);
  }, [items]);

  const updateQuery = (nextQuery: string | number) => {
    if (nextQuery <= 0 || nextQuery > itemPages) {
      return false;
    }
    setLoading(true);

    router.push(
      {
        query: {
          ...router.query,
          [id]: nextQuery,
        },
      },
      undefined,
      { scroll: false }
    );
  };

  return {
    updateQuery,
    isLoading,
    pageNum,
  };
};
