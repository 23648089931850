/* eslint-disable no-extend-native */
export const removeDiacritics = (text: string) => {
  if (typeof String.prototype.replaceAll === 'undefined') {
    String.prototype.replaceAll = (match, replace) => {
      return (this || '').replace(
        new RegExp(match, 'g'),
        () => replace as string
      );
    };
  }
  return text
    .replaceAll('ą', 'a')
    .replaceAll('ć', 'c')
    .replaceAll('ę', 'e')
    .replaceAll('ł', 'l')
    .replaceAll('ń', 'n')
    .replaceAll('ó', 'o')
    .replaceAll('ś', 's')
    .replaceAll('ż', 'z')
    .replaceAll('ź', 'z');
};
