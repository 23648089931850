import {
  Box,
  SectionTitle,
  Typography,
  StyledInput,
  Button,
  StyledTextarea,
  AvatarBox,
  Loader,
} from 'components';
import SVG from 'react-inlinesvg';
import React from 'react';
import { API } from 'API';
import { HostDetails } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { useForm, Controller } from 'react-hook-form';
import { Container } from 'react-grid-system';
import parser from 'ua-parser-js';
import styled from 'styled-components';

interface Form {
  message: string;
  signature: string;
  email: string;
  subject: string;
}

const Wrapper = styled.div`
  p {
    margin-bottom: 10px;
  }
`;

export const ContentCreatorDetails: React.FC<{ hostDetails: HostDetails }> = ({
  hostDetails,
}) => {
  const { isMobile } = React.useContext(MobileContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Form>();

  const onSubmit = async (data: Form) => {
    setLoading(true);

    const p = new parser.UAParser();
    const browser = p.getBrowser();
    const os = p.getOS();
    let userIp = 'unknown';
    const browserData = browser
      ? `${browser?.name} ${browser.version}`
      : navigator?.userAgent;
    const osData = os ? `${os?.name} ${os.version}` : navigator?.platform;

    const askIp = await Promise.allSettled([
      fetch('https://geolocation-db.com/json/'),
    ]);
    if (askIp[0].status === 'fulfilled') {
      const askIpJson = await Promise.allSettled([askIp[0].value.json()]);
      if (askIpJson[0].status === 'fulfilled') {
        userIp = askIpJson[0]?.value?.IPv4 || 'unknown';
      }
    }

    API.contactCreator(
      data.subject,
      data.signature,
      data.email,
      data.message,
      hostDetails.articleId,
      userIp,
      browserData,
      'computerName',
      osData,
      'userName',
      'sourcePort'
    )

      .then(() => {
        setLoading(false);
      })
      .finally(() => {
        setSuccess(true);
        reset({ message: '', email: '', signature: '' });
      });
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  const formValid = Object.keys(errors).length === 0;

  return (
    <Wrapper>
      <Container>
        <Box
          as="article"
          py={4}
          display="flex"
          flexDirection="column"
          position="relative"
        >
          <SectionTitle
            borderColor="main.primary"
            borderWidth={5}
            shadow={!isMobile}
            shadowOffset={4}
          >
            Zespół
          </SectionTitle>
          <Box
            py={4}
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexDirection={{ desktop: 'row', mobile: 'column' }}
            alignItems={{ desktop: 'initial', mobile: 'center' }}
          >
            <Box
              m={3}
              maxWidth={isMobile ? 200 : 300}
              maxHeight={isMobile ? 200 : 300}
            >
              <AvatarBox
                src={`https://static.prsa.pl/images/${hostDetails?.fileUid}.jpg?format=700`}
                size="300px"
              />
            </Box>
            <Box m={3} width={{ desktop: '33%', mobile: '100%' }} as="section">
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
              >
                <Typography
                  variant="h2"
                  as="h2"
                  py={1}
                  marginBottom="10px"
                  textAlign={{
                    desktop: 'initial',
                    tablet: 'center',
                    mobile: 'center',
                  }}
                >
                  {`${hostDetails?.name} ${hostDetails?.surname}`}
                </Typography>
                <Typography
                  variant="labelBig"
                  as="h3"
                  pt={2}
                  pb={3}
                  marginBottom="10px"
                  textAlign={{
                    desktop: 'initial',
                    tablet: 'center',
                    mobile: 'center',
                  }}
                >
                  {hostDetails?.profession}
                </Typography>
                <Typography variant="listContent">
                  {' '}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: hostDetails?.content,
                    }}
                  />
                </Typography>
              </Box>
            </Box>
            <Box m={3} width={{ desktop: '33%', mobile: '100%' }} as="section">
              <Box
                mb={isMobile ? 10 : 50}
                borderBottom="1px solid"
                borderColor="neutral.grayWithOpacity"
              >
                <SectionTitle
                  borderColor="main.primary"
                  borderWidth={5}
                  fontSize={isMobile ? 1 : 3}
                  decoratorTop="9%"
                >
                  Napisz do mnie
                </SectionTitle>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="subject"
                  rules={{
                    required: { value: true, message: 'Pole jest wymagane' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StyledInput
                      width="100%"
                      variant="sectionTitle"
                      height="24px"
                      fontSize={3}
                      color="neutral.dark"
                      placeholderColor="neutral.dark"
                      borderLeft="5px solid"
                      isTitleInput
                      borderBottom={value ? 'neutral.placeholder' : 'none'}
                      pl={2}
                      borderColor={
                        errors.subject
                          ? 'main.secondary'
                          : value
                          ? 'main.primary'
                          : 'neutral.placeholder'
                      }
                      name="title"
                      placeholder="Wpisz Tytuł"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  mt={isMobile ? 2 : 3}
                  pt={3}
                >
                  <Controller
                    control={control}
                    name="message"
                    rules={{
                      required: { value: true, message: 'Pole jest wymagane' },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StyledTextarea
                        placeholder="Treść wiadomości"
                        resize="vertical"
                        onChange={onChange}
                        value={value}
                        border={errors.message ? '2px solid red' : ''}
                      />
                    )}
                  />
                  {errors.message && (
                    <Typography variant="error" color="main.secondary">
                      {errors.message.message}
                    </Typography>
                  )}

                  <Box my={3}>
                    <Controller
                      control={control}
                      name="signature"
                      rules={{
                        required: {
                          value: true,
                          message: 'Pole jest wymagane',
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StyledInput
                          width="100%"
                          variant="input"
                          color="neutral.dark"
                          placeholderColor="neutral.placeholder"
                          borderBottom="2px solid"
                          borderColor={
                            errors.signature
                              ? 'main.secondary'
                              : value
                              ? 'main.primary'
                              : 'neutral.placeholder'
                          }
                          name="signature"
                          placeholder="Twój Podpis"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.signature && (
                      <Typography variant="error" color="main.secondary">
                        {errors.signature.message}
                      </Typography>
                    )}
                  </Box>
                  <Box my={3}>
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        required: {
                          value: true,
                          message: 'Pole jest wymagane',
                        },
                        pattern: {
                          value:
                            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                          message: 'Nieprawidłowy format',
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StyledInput
                          width="100%"
                          variant="input"
                          color="neutral.dark"
                          placeholderColor="neutral.placeholder"
                          borderBottom="2px solid"
                          borderColor={
                            errors.email
                              ? 'main.secondary'
                              : value
                              ? 'main.primary'
                              : 'neutral.placeholder'
                          }
                          name="email"
                          placeholder="Twój Email"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.email && (
                      <Typography variant="error" color="main.secondary">
                        {errors.email.message}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box width={125} mt={4}>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button
                      color="main.primary"
                      disabled={!formValid || loading}
                      decorator={<SVG src="/icons/arrow_right.svg" />}
                    >
                      <Typography variant="caption3">wyślij</Typography>
                    </Button>
                  )}
                </Box>
                {success && (
                  <Typography marginTop={3} variant="h3">
                    Wiadomość wysłana poprawnie
                  </Typography>
                )}
              </form>
            </Box>
          </Box>
        </Box>
      </Container>
    </Wrapper>
  );
};
