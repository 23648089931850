/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, SectionTitle } from 'components';
import React from 'react';
import { Container } from 'react-grid-system';
import styled from 'styled-components';
import { MobileContext } from 'utils/mobileContext';

const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
  border: none;

  #search {
    display: none;
  }
`;

export const Frequency: React.FC = () => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box py={4}>
      <Container fluid md>
        <SectionTitle
          borderColor="main.primary"
          borderWidth={5}
          shadow={!isMobile}
          shadowOffset={4}
        >
          Częstotliwości
        </SectionTitle>
        <Box mt={4}>
          <Iframe
            id="czestotliwosci"
            title="czestotliwosci"
            src="https://www2.polskieradio.pl/czestotliwosci/czestotliwosci_jedynki/"
          />
        </Box>
      </Container>
    </Box>
  );
};
