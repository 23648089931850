import { SearchBox, Box, PaginatedList, Typography, Link } from 'components';
import React from 'react';
import { Container } from 'react-grid-system';
import { ArticleItem, PageProps } from 'models';
import { usePaginatedList } from 'utils/usePaginatedList';
import { MobileContext } from 'utils/mobileContext';
import { useRouter } from 'next/router';

export const SearchResultsDesktop: React.FC<PageProps<ArticleItem[]>> = ({
  data: articles,
  totalArticles = 0,
  title = '',
  categories,
}) => {
  const pages = Math.ceil(totalArticles / 16);
  const { pathname } = useRouter();

  const { isLoading, updateQuery, pageNum } = usePaginatedList({
    items: articles,
    itemPages: pages,
    id: 'p',
  });

  const { isMobile } = React.useContext(MobileContext);

  const isTags = pathname.includes('tagi');
  return (
    <>
      <Box paddingBottom={4} paddingTop="2px" overflow="hidden" width="100%">
        {!isTags && (
          <Box
            width="100%"
            height="148px"
            backgroundColor="neutral.mediumGray"
            marginTop="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Container fluid sm style={{ width: '100%' }}>
              <Box display="flex" width="100%">
                <Typography width="20%">Zobacz więcej na temat:</Typography>
                <Box display="flex" flexWrap="wrap" width="80%">
                  {categories?.map((category, index) => {
                    return (
                      <Box marginRight="10px">
                        <Link href={`/artykuly/${category?.id}`}>
                          <Box display="flex">
                            <Typography color="#0645AD">
                              {category.name}
                            </Typography>
                            {index + 1 !== categories.length && (
                              <Box
                                width="2px"
                                height="20px"
                                backgroundColor="neutral.gray"
                                marginLeft="10px"
                              />
                            )}
                          </Box>
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Container>
          </Box>
        )}
        <Container fluid sm>
          {isTags && (
            <Box
              width="fit-content"
              borderColor="main.primary"
              border="3px solid"
            >
              <Typography
                variant="h2"
                px={3}
                py={2}
                color="main.primary"
                as="h2"
              >
                {title?.toUpperCase()}
              </Typography>
            </Box>
          )}
          {articles &&
            (totalArticles !== 0 ? (
              <PaginatedList
                page={pageNum}
                loading={isLoading}
                pageCount={pages}
                gridTemplateColumns="1fr"
                items={
                  articles?.map((article, index) => (
                    <Box
                      key={article.id}
                      height={isMobile ? 'auto' : 250}
                      mb={4}
                      mx={2}
                      width="100%"
                    >
                      <SearchBox
                        article={article}
                        index={index}
                        total={totalArticles}
                        isTags={isTags}
                        title={title}
                      />
                    </Box>
                  )) || []
                }
                onNext={() => updateQuery(pageNum + 1)}
                onPrev={() => updateQuery(pageNum - 1)}
                onChange={(val: number) => updateQuery(val)}
              />
            ) : (
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                marginY="50px"
              >
                <Typography variant="h3">
                  Brak wyników wyszukiwania dla podanej frazy.
                </Typography>
              </Box>
            ))}
        </Container>
      </Box>
    </>
  );
};
