import {
  Box,
  DayPicker,
  SectionTitle,
  PlaylistBox,
  Typography,
} from 'components';
import React from 'react';
import { Container } from 'react-grid-system';
import { getDateFromQuery, getTime, getTodayDateString } from 'utils/date';
import { ShowPlaylist, PlaylistItem } from 'models';
import styled from 'styled-components';
import { MobileContext } from 'utils/mobileContext';
import { useRouter } from 'next/router';
import { v4 as uuid } from 'uuid';
import axios from 'axios';

export interface IPlaylistGrid {
  isTablet?: boolean;
}

const PlaylistGrid = styled.div<IPlaylistGrid>`
  display: grid;
  grid-template-columns: ${({ isTablet }) => (isTablet ? '1fr' : '1fr 1fr')};
  gap: ${({ theme }) => `${theme.space[3]}px`};
  row-gap: ${({ theme, isTablet }) =>
    isTablet ? `${theme.space[3]}px` : `${theme.space[2]}px`};
`;

interface IPlaylist {
  playlists: ShowPlaylist[];
}

export const Playlist: React.FC<IPlaylist> = ({ playlists: initialData }) => {
  console.log(initialData);
  const { query } = useRouter();
  const currentDate = getDateFromQuery(query.date);
  const [data, setData] = React.useState(initialData);
  const { isMobile, isTablet } = React.useContext(MobileContext);
  React.useEffect(() => {
    if (query.date) {
      axios
        .get(`/api/playlist?date=${currentDate}`)
        .then(({ data }) => setData(data));
    }
  }, [query.date]);

  return (
    <Box py={4} my={isMobile ? 5 : 3} as="article">
      <Container fluid md>
        <SectionTitle
          borderColor="main.primary"
          borderWidth={5}
          shadow={!isMobile}
          shadowOffset={4}
          sideText={getTodayDateString(currentDate)}
        >
          Playlista
        </SectionTitle>
        <Box my={4}>
          <DayPicker maxToday range={isTablet ? 4 : 6} />
          {/* <Box mt={4}>
            <TimePicker
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
          </Box> */}
        </Box>
        {data.map(({ title, playlistItems, startTime, stopTime }) => (
          <React.Fragment key={uuid()}>
            <Box
              as="section"
              display="flex"
              alignItems="flex-end"
              borderBottom="1px solid"
              borderColor="neutral.grayWithOpacity"
              flexDirection={isMobile ? 'column-reverse' : undefined}
              style={{ alignItems: isMobile ? 'center' : undefined }}
              pb={2}
              mt={5}
              mb={2}
            >
              <Typography
                variant={isMobile ? 'playlistTitleMobile' : 'playlistTitle'}
                as="h4"
                textAlign="center"
                color="neutral.dark"
              >
                {title.toUpperCase()}
              </Typography>
              <Typography
                variant="lead2"
                as="span"
                ml={isMobile ? 0 : 2}
                color="neutral.placeholder"
                width={isMobile ? undefined : '150px'}
              >
                {getTime(new Date(startTime))} - {getTime(new Date(stopTime))}
              </Typography>
            </Box>
            <PlaylistGrid isTablet={isTablet}>
              {playlistItems.map((episode: PlaylistItem) => (
                <PlaylistBox key={uuid()} sound={episode} mobile={isMobile} />
              ))}
            </PlaylistGrid>
          </React.Fragment>
        ))}
      </Container>
    </Box>
  );
};
