/* eslint-disable no-nested-ternary */
import { ArticleBox, Box, PaginatedList, SectionTitle } from 'components';
import React from 'react';
import { Container, Col } from 'react-grid-system';
import { ArticleItem, PageProps } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { usePaginatedList } from 'utils/usePaginatedList';
import { useRouter } from 'next/router';
import axios from 'axios';

export const UkraineArticleList: React.FC<PageProps<ArticleItem[]>> = ({
  data: initialData,
  totalArticles = 0,
  title,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  const pages = Math.ceil(totalArticles / 16);
  const { query } = useRouter();
  const [data, setData] = React.useState<ArticleItem[]>(initialData);

  const { isLoading, updateQuery, pageNum } = usePaginatedList({
    items: data,
    itemPages: pages,
    id: 'p',
  });

  const getTemplateColumns = 'repeat(auto-fit, minmax(285px, 1fr))';

  React.useEffect(() => {
    if (query.p) {
      axios
        .get(`/api/ukraine?page=${query.p}`)
        .then(({ data }) => setData(data));
    }
  }, [query.p]);

  return (
    <Box py={4}>
      <Container fluid md>
        <SectionTitle
          borderColor="main.primary"
          borderWidth={5}
          shadow={!isMobile}
          shadowOffset={4}
        >
          {title}
        </SectionTitle>
        <PaginatedList
          page={pageNum}
          loading={isLoading}
          pageCount={pages}
          gridTemplateColumns={getTemplateColumns}
          items={
            data?.map((article) => (
              <Col xs={12}>
                <Box
                  key={article.id}
                  height={isMobile ? undefined : 230}
                  mb={4}
                  as="article"
                >
                  <ArticleBox article={article} />
                </Box>
              </Col>
            )) || []
          }
          onNext={() => updateQuery(pageNum + 1)}
          onPrev={() => updateQuery(pageNum - 1)}
          onChange={(val: number) => updateQuery(val)}
        />
      </Container>
    </Box>
  );
};
