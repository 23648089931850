import {
  Banner,
  Box,
  MainSchedulePlaylist,
  StreamingSection,
} from 'components';
import { IMain } from 'pages';
import dynamic from 'next/dynamic';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';
import { LazyComponent } from 'components/atoms/LazyComponent/LazyComponent';
import { Container } from 'react-grid-system';

const LatestSection = dynamic(
  () => import('components/organisms/LatestSection')
);

const PodcastSection = dynamic(
  () => import('components/organisms/PodcastSection')
);

const NewsSection = dynamic(() => import('components/organisms/NewsSection'));

const BannerSection = dynamic(
  () => import('components/organisms/BannerSection')
);

const LotteryBannerSection = dynamic(
  () => import('components/organisms/LotteryBannerSection')
);

const ActionsSection = dynamic(
  () => import('components/organisms/ActionsSection')
);

const MosaicSection = dynamic(
  () => import('components/organisms/MosaicSection')
);

const MusicMosaicSection = dynamic(
  () => import('components/organisms/MusicMosaicSection')
);

const PatronagesSection = dynamic(
  () => import('components/organisms/PatronagesSection')
);

const ConcertsSection = dynamic(
  () => import('components/organisms/ConcertsSection')
);

const TabMosaicSection = dynamic(
  () => import('components/organisms/TabMosaicSection')
);

const AudioSlidersSection = dynamic(
  () => import('components/organisms/AudioSlidersSection')
);

const SpecialBroacastSection = dynamic(
  () => import('components/organisms/SpecialBroacastSection')
);

const SpecialServiceSection = dynamic(
  () => import('components/organisms/SpecialServiceSection')
);

const TeamSection = dynamic(() => import('components/organisms/TeamSection'));

export const MainDesktop: React.FC<IMain> = ({
  soonOnAirContent,
  highlightedNewsContent,
  allNewsContent,
  teamContent,
  actionsContent,
  signalsOfTheDayContent,
  highNoonContent,
  moreOfTheWorldContent,
  musicContent,
  musicalJedynkaContent,
  musicByNightContent,
  goodOldDaysContent,
  eurekaContent,
  discoverersEveningContent,
  concertsContent,
  seriesContent,
  exclusivesContent,
  fourSeasonsContent,
  culturedJedynkaContent,
  myBooksContent,
  mustReadContent,
  veryCinematicShowContent,
  scheduleContent,
  playlistContent,
  patronagesContent,
  streamContent,
  stream2Content,
  stream3Content,
  stream4Content,
  bannerTopContent,
  banner0Content,
  banner1Content,
  banner2Content,
  bannerSpecialContent,
  bannerPromo1Content,
  bannerPromo2Content,
  newsAudioBoxContent,
  jedynkaBooksContent,
  wasReadContent,
  currentReadContent,
  matysiakowieContent,
  jezioranyContent,
  recordOfTheWeekContent,
  recommendedRecordsContent,
  newsBoxContent,
  inOpenCardsContent,
  whereIsMrCogitoContent,
  newsInfoContent,
  newsMosaicContent,
  liveHistoryContent,
  jedynkaExpressContent,
  allCultureContent,
  allHistoryContent,
  allSocietyContent,
  allScienceContent,
  allLiteratureContent,
  latestBoxContent,
  lzrContent,
}) => {
  const { isMobile } = React.useContext(MobileContext);

  return (
    <Box py={4} mt={isMobile ? '100px' : 0} backgroundColor="neutral.white">
      {bannerTopContent && (
        <Box
          mb={4}
          mt={isMobile ? -5 : -4}
          maxWidth={isMobile ? 750 : 950}
          mx="auto"
        >
          <LazyComponent height={250}>
            <BannerSection content={bannerTopContent} />
          </LazyComponent>
        </Box>
      )}
      <MainSchedulePlaylist
        scheduleContent={scheduleContent}
        playlistContent={playlistContent}
      />
      {streamContent && (
        <StreamingSection streamContent={streamContent} streamFallback />
      )}
      {stream2Content && (
        <StreamingSection streamContent={stream2Content} streamFallback />
      )}
      {stream4Content && <StreamingSection streamContent={stream4Content} />}
      {stream3Content && <StreamingSection streamContent={stream3Content} />}
      {banner0Content && (
        <LazyComponent height={220}>
          <BannerSection content={banner0Content} />
        </LazyComponent>
      )}
      <LazyComponent height={735}>
        <LatestSection
          mosaicContent={newsBoxContent}
          listContent={soonOnAirContent}
          latestBox={latestBoxContent}
        />
      </LazyComponent>
      <LazyComponent height={220}>
        <LotteryBannerSection />
      </LazyComponent>
      {/* <LazyComponent height={220}>
        <Container fluid md>
          <Banner
            link="https://paryz2024.polskieradio.pl/"
            src="/images/banner_paryz.jpg"
          />
        </Container>
      </LazyComponent> */}
      <LazyComponent height={656}>
        <NewsSection
          tabs={[
            {
              name: 'Sygnały dnia',
              id: 129,
              content: signalsOfTheDayContent,
              isRadioShow: true,
            },
            {
              name: 'W samo południe',
              id: 473,
              content: highNoonContent,
              isRadioShow: true,
            },
            {
              name: 'Więcej świata',
              id: 1696,
              content: moreOfTheWorldContent,
              isRadioShow: true,
            },
            {
              name: 'W otwarte karty',
              id: 8805,
              content: inOpenCardsContent,
              isRadioShow: true,
            },
            {
              name: 'Gdzie jest Pan Cogito',
              id: 7630,
              content: whereIsMrCogitoContent,
              isRadioShow: true,
            },
            { name: 'Wszystko', id: 1552, content: newsInfoContent },
          ]}
          audio={newsAudioBoxContent}
          highlight={highlightedNewsContent}
          allNews={allNewsContent}
        />
      </LazyComponent>
      <LazyComponent height={419}>
        <ActionsSection
          actionsContent={lzrContent}
          seeMore="https://latozradiem.pl/"
          title="Lato z Radiem"
        />
      </LazyComponent>
      {banner1Content && (
        <LazyComponent height={220}>
          <BannerSection content={banner1Content} />
        </LazyComponent>
      )}
      {/* <LazyComponent height={419}>
        <ActionsSection
          actionsContent={lzrContent}
          seeMore="https://latozradiem.pl/"
          title="Lato z radiem"
        />
      </LazyComponent> */}
      <LazyComponent height={674}>
        <MosaicSection
          content={newsMosaicContent}
          promo1={bannerPromo1Content}
          promo2={bannerPromo2Content}
        />
      </LazyComponent>
      <LazyComponent height={419}>
        <ActionsSection
          actionsContent={actionsContent}
          seeMore="/artykuly/1579"
          title="akcje jedynki"
        />
      </LazyComponent>
      {banner2Content && (
        <LazyComponent height={220}>
          <BannerSection
            content={banner2Content}
            background="neutral.light"
            paddingBottom
          />
        </LazyComponent>
      )}
      <LazyComponent height={842}>
        <MusicMosaicSection
          tabs={[
            {
              name: 'Muzyczna Jedynka',
              id: 160,
              content: musicalJedynkaContent,
              isRadioShow: true,
            },
            {
              name: 'Muzyka nocą',
              id: 3730,
              content: musicByNightContent,
              isRadioShow: true,
            },
            {
              name: 'Tak to bywało',
              id: 3727,
              content: goodOldDaysContent,
              isRadioShow: true,
            },
            { name: 'Wszystko', id: 1814, content: musicContent },
          ]}
          recordOfTheWeek={recordOfTheWeekContent}
          recommendedRecords={recommendedRecordsContent}
        />
      </LazyComponent>
      <LazyComponent height={451}>
        <ConcertsSection concertsContent={concertsContent} />
      </LazyComponent>
      <LazyComponent height={952}>
        <TabMosaicSection
          currentReadBooks={currentReadContent}
          readBooks={wasReadContent}
          tabs={[
            {
              tabName: 'Literatura',
              options: [
                {
                  name: 'Lektury Jedynki',
                  content: jedynkaBooksContent,
                  id: 1530,
                  isRadioShow: false,
                },
                {
                  name: 'Moje książki',
                  content: myBooksContent,
                  id: 173,
                  isRadioShow: true,
                },
                {
                  name: 'Wszystko',
                  content: allLiteratureContent,
                  id: 9639,
                },
              ],
            },
            {
              tabName: 'Nauka',
              options: [
                // {
                //   name: 'Naukowy zawrót głowy',
                //   content: scientificVertigoContent,
                //   id: 179,
                // },
                {
                  name: 'Eureka',
                  content: eurekaContent,
                  id: 5098,
                  isRadioShow: true,
                },
                {
                  name: 'Wieczór odkrywców',
                  content: discoverersEveningContent,
                  id: 161,
                  isRadioShow: true,
                },
                {
                  name: 'Wszystko',
                  content: allScienceContent,
                  id: 1550,
                },
              ],
            },
            {
              tabName: 'Historia',
              options: [
                // {
                //   name: 'Świat kresów',
                //   content: worldOfEastContent,
                //   id: 5103,
                // },
                // {
                //   name: 'Polscy władcy i królowie',
                //   content: polishRulersAndKingsContent,
                //   id: 4814,
                // },
                {
                  name: 'Historia żywa',
                  content: liveHistoryContent,
                  id: 5102,
                  isRadioShow: true,
                },
                {
                  name: 'Wszystko',
                  content: allHistoryContent,
                  id: 4565,
                },
              ],
            },
            {
              tabName: 'Kultura',
              options: [
                {
                  name: 'Kulturalna jedynka',
                  content: culturedJedynkaContent,
                  id: 178,
                  isRadioShow: true,
                },
                {
                  name: 'Moje książki',
                  content: myBooksContent,
                  id: 173,
                  isRadioShow: true,
                },
                {
                  name: 'Lektury Jedynki',
                  content: mustReadContent,
                  id: 1530,
                  isRadioShow: true,
                },
                {
                  name: 'Magazyn bardzo filmowy',
                  content: veryCinematicShowContent,
                  id: 4457,
                  isRadioShow: true,
                },
                {
                  name: 'Wszystko',
                  content: allCultureContent,
                  id: 1551,
                },
              ],
            },
            {
              tabName: 'Społeczeństwo',
              options: [
                // {
                //   name: 'Damska Torebka',
                //   content: womansPurseContent,
                //   id: 5071,
                // },
                {
                  name: 'Cztery pory roku',
                  content: fourSeasonsContent,
                  id: 163,
                  isRadioShow: true,
                },
                // {
                //   name: 'Twarzą w twarz',
                //   content: faceToFaceContent,
                //   id: 5112,
                // },
                {
                  name: 'Ekspres jedynki',
                  content: jedynkaExpressContent,
                  id: 5346,
                  isRadioShow: true,
                },
                {
                  name: 'Wszystko',
                  content: allSocietyContent,
                  id: 4411,
                },
              ],
            },
          ]}
        />
      </LazyComponent>
      <LazyComponent height={541}>
        <AudioSlidersSection
          seriesContent={seriesContent}
          exclusivesContent={exclusivesContent}
        />
      </LazyComponent>
      <LazyComponent height={381}>
        <PodcastSection
          podcastsContent={[
            {
              id: 240,
              title: 'Podróże Czejarka',
              podcastUrl:
                'https://www.polskieradio.pl/Podcast/e4276181-10f5-4443-bf98-6865c58a0d00',
              guid: 'e4276181-10f5-4443-bf98-6865c58a0d00',
              image: {
                thumbnail:
                  'https://static.prsa.pl/images/64fd16a2-b539-4fb2-be73-8e8d7c03ce31.jpg',
              },
            },
            {
              id: 254,
              title: 'Eureka Extra',
              podcastUrl:
                'https://www.polskieradio.pl/Podcast/283b5a54-b53d-4ea8-a22e-01cee6c480b5',
              creationDate: '2021-10-19T06:40:20.157Z',
              guid: '283b5a54-b53d-4ea8-a22e-01cee6c480b5',
              image: {
                thumbnail:
                  'https://static.prsa.pl/images/09d8e0e6-3f05-423d-859e-dd511dac596b.jpg',
              },
            },
            {
              id: 249,
              title: 'Być kobietą',
              podcastUrl:
                'https://www.polskieradio.pl/Podcast/7abee0df-5c74-40b1-a580-4b23baa3656a',
              guid: '7abee0df-5c74-40b1-a580-4b23baa3656a',
              image: {
                thumbnail:
                  'https://static.prsa.pl/images/6c34d98f-94e0-4a3b-8393-1b0aa6a7f16a.jpg',
              },
            },
          ]}
        />
      </LazyComponent>
      <LazyComponent height={263}>
        <SpecialBroacastSection
          matysiakowieContent={matysiakowieContent}
          jezioranyContent={jezioranyContent}
        />
      </LazyComponent>
      {bannerSpecialContent && (
        <LazyComponent height={420}>
          <SpecialServiceSection content={bannerSpecialContent} />
        </LazyComponent>
      )}
      <PatronagesSection content={patronagesContent} />
      <LazyComponent height={451}>
        <TeamSection teamContent={teamContent} />
      </LazyComponent>
    </Box>
  );
};
