import { Box, ContactForm, SectionTitle, SocialsLarge } from 'components';
import { Container } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';
import React from 'react';

export const ContactDesktop = () => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box as="article" py={4}>
      <Container fluid md>
        <SectionTitle
          borderColor="main.primary"
          borderWidth={5}
          shadow={!isMobile}
          shadowOffset={4}
          fontSize={20}
        >
          Kontakt
        </SectionTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ desktop: 'row', mobile: 'column' }}
          mt={4}
          as="main"
        >
          <ContactForm />
          <SocialsLarge />
        </Box>
      </Container>
    </Box>
  );
};
