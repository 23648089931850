/* eslint-disable no-nested-ternary */
import { ArticleBox, Box, PaginatedList, SectionTitle } from 'components';
import React from 'react';
import { Container, Col } from 'react-grid-system';
import { ArticleItem, PageProps } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { usePaginatedList } from 'utils/usePaginatedList';
import { useRouter } from 'next/router';
import axios from 'axios';

export const ArticleList: React.FC<PageProps<ArticleItem[]>> = ({
  data: initialData,
  totalArticles = 0,
  title,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  const pages = Math.ceil(totalArticles / 16);
  const { query } = useRouter();
  const [data, setData] = React.useState<ArticleItem[]>(initialData);
  const { pathname } = useRouter();

  const { isLoading, updateQuery, pageNum } = usePaginatedList({
    items: data,
    itemPages: pages,
    id: 'p',
  });

  const getEuranetBanner = () => {
    if (query.id === '6389') {
      return (
        <Box
          as="img"
          src="https://static.prsa.pl/55b89d59-eed4-4d32-9a15-5077c6243ddc.file"
          alt="Sponsor"
          width={isMobile ? '160px' : '200px'}
          mb={isMobile ? 2 : 4}
          ml="auto"
          display="block"
          transform={isMobile ? 'translate(20px, 0)' : 'translate(20px, -50px)'}
        />
      );
    }
  };
  const getTemplateColumns = 'repeat(auto-fit, minmax(285px, 1fr))';

  React.useEffect(() => {
    if (query.p && pathname.includes('artykuly')) {
      axios
        .get(`/api/articles?id=${query.id}&page=${query.p}`)
        .then(({ data }) => setData(data));
    }
    if (query.p && pathname.includes('zobacz-takze')) {
      axios
        .get(`/api/articlesBySectionId?sectionId=7&page=${query.p}`)
        .then(({ data }) => setData(data));
    }
  }, [query.p]);

  return (
    <Box py={4}>
      <Container fluid md>
        {getEuranetBanner()}
        <SectionTitle
          borderColor="main.primary"
          borderWidth={5}
          shadow={!isMobile}
          shadowOffset={4}
        >
          {title}
        </SectionTitle>
        <PaginatedList
          page={pageNum}
          loading={isLoading}
          pageCount={pages}
          gridTemplateColumns={getTemplateColumns}
          items={
            data?.map((article) => (
              <Col xs={12}>
                <Box
                  key={article.id}
                  height={isMobile ? undefined : 230}
                  mb={4}
                  as="article"
                >
                  <ArticleBox article={article} />
                </Box>
              </Col>
            )) || []
          }
          onNext={() => updateQuery(pageNum + 1)}
          onPrev={() => updateQuery(pageNum - 1)}
          onChange={(val: number) => updateQuery(val)}
        />
      </Container>
    </Box>
  );
};
