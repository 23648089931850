/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import { PageProps, ArticlePage } from 'models';
import {
  Typography,
  Box,
  ReadingTime,
  ArticleQuote,
  ShareLinks,
  ReadAlsoSide,
  ReadAlsoSideRecommended,
  ArticlePlayer,
  ArticleGallery,
  ArticleTag,
  ArticlePlaylist,
  Quiz,
  Link,
  LiveTicker,
} from 'components';
import { formatToDateTimeString } from 'utils/date';
import { Row, Col, Container } from 'react-grid-system';
import styled from 'styled-components';
import parse, { Element, domToReact, DOMNode } from 'html-react-parser';
import { MobileContext } from 'utils/mobileContext';
import { Tweet } from 'react-twitter-widgets';
import { isHTML } from 'utils/isHtml';
import { ArticleTopBanner } from 'components/atoms';

const Wrapper = styled.div`
  .playlistBlock,
  .tagsBlock {
    display: none;
  }
  a {
    color: #305cb4;
    text-decoration: underline;
  }
  ul,
  ol {
    margin-left: 1em;
    ${({ theme }) => theme.textStyles.listContent}
  }

  .line {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100px;
    height: 5px;
    background-color: rgb(246, 142, 30);
  }

  ul,
  ol {
    list-style: none;
    width: 100%;
    flex-direction: column;
    display: flex;
    margin-top: ${({ theme }) => theme.space[3]}px;
    margin-bottom: ${({ theme }) => theme.space[3]}px;
    background-color: ${({ theme }) => theme.palette.neutral.light};
    padding: ${({ theme }) => theme.space[3]}px;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  ul li,
  ol li {
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.space[3]}px;
    margin-bottom: ${({ theme }) => theme.space[3]}px;
  }
  ul li a,
  ol li a,
  ol li {
    ${({ theme }) => theme.textStyles.lead3};
    font-weight: 700;
    margin-top: ${({ theme }) => theme.space[3]}px;
    margin-bottom: ${({ theme }) => theme.space[3]}px;
    cursor: 'pointer';
    text-decoration: none;
    color: ${({ theme }) => theme.palette.neutral.dark};
  }
  ul li:hover a,
  ol li:hover a {
    color: ${({ theme }) => theme.palette.main.primary};
  }
  ul li::before,
  ol li:before {
    content: '';
    background-color: ${({ theme }) => theme.palette.main.primary};
    align-self: flex-start;
    margin-top: 10px;
    border-radius: 50%;
    font-weight: bold;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: -10px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  @media (max-width: 768px) {
    ul li a,
    ol li a,
    ul li {
      ${({ theme }) => theme.textStyles.readAlsoMobile};
      font-weight: 500;
    }
    ol li,
    ul li {
      margin-bottom: 5px;
    }
    p {
      margin-top: ${({ theme }) => theme.space[3]}px;
      margin-bottom: ${({ theme }) => theme.space[2]}px;
      ${({ theme }) => theme.textStyles.bodyMobile};
    }
  }
  p {
    margin-top: ${({ theme }) => theme.space[3]}px;
    margin-bottom: ${({ theme }) => theme.space[2]}px;
  }
  iframe {
    /* object-fit: scale-down; */
    max-height: 100%;
    max-width: 100%;
  }
  .embed-video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin: 0;
  }
  .embed-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
  table {
    border: 1px solid ${({ theme }) => theme.palette.neutral.dark};
    color: ${({ theme }) => theme.palette.neutral.dark};
    background: ${({ theme }) => theme.palette.neutral.white};
    border-collapse: collapse;
    thead th,
    tfoot th {
      color: ${({ theme }) => theme.palette.neutral.dark};
      background: ${({ theme }) => theme.palette.neutral.white}1A;
    }
    caption {
      padding: ${({ theme }) => theme.space[2]}px;
    }
    th,
    td {
      padding: ${({ theme }) => theme.space[2]}px;
      border: 1px solid ${({ theme }) => theme.palette.neutral.gray};
    }
    tbody tr:nth-of-type(odd) {
      background: ${({ theme }) => theme.palette.neutral.dark}1A;
    }
  }
  .articleQuiz {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .quizLabel {
    background-color: #f77a0f;
    color: #ffffff;
    font-family: Muli;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
  }
  .quizBg {
    background: gray;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    position: relative;
  }
  .quizTitle {
    border: none;
    padding: 10px 20px;
    background-color: #003856;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 30px;
  }
  .quizList {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px !important;
    margin-left: 0 !important;
  }
  .quizOption {
    color: #f77a0f;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    border: none;
    text-align: center;
    padding: 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
  }
  .quizError {
    color: #dd2823;
    font-weight: 700;
    margin-bottom: 30px;

    &.inactive {
      display: none;
    }
  }
`;

const getQuizId = (content: string): number | null => {
  if (typeof window !== 'undefined') {
    const res = content;
    const div = document.createElement('div');

    div.innerHTML = res;
    const quizElement = div.querySelector('.articleQuiz');
    const quizId = quizElement?.getAttribute('data-quizid');
    return Number(quizId);
  }
  return null;
};

const getQuizQuestion = (content: string): string | undefined | null => {
  if (typeof window !== 'undefined') {
    const res = content;
    const div = document.createElement('div');

    div.innerHTML = res;
    const quizElement = div.querySelector('.articleQuiz');
    const quizText = quizElement?.innerHTML;
    return quizText;
  }
  return null;
};

export const ArticleDesktop: React.FC<PageProps<ArticlePage>> = ({
  data: {
    articleData,
    galleryData,
    articlesData,
    playlistData,
    recommendedData,
    articleBanner,
    articleBanner2,
  },
}) => {
  const data = articleData;
  const { isMobile } = React.useContext(MobileContext);
  const content = useRef('');
  const quizId = getQuizId(content.current);
  const quizQuestion = getQuizQuestion(content.current);
  if (typeof window === 'undefined') {
    // eslint-disable-next-line react/no-danger
    return null;
  }

  const router = useRouter();

  content.current = data?.content;
  content.current = content.current.replaceAll(
    '***',
    '<div class="line"></div>'
  );
  content.current = content.current.replaceAll(
    '<!--GALLERIE-->',
    '<div class="gallery"></div>'
  );
  let attachments = data?.attachments
    ? [
        [
          ...data.attachments.filter((el) => {
            return el.fileType === 'Image';
          }),
        ],
        [
          ...data.attachments.filter((el) => {
            return el.fileType === 'Audio';
          }),
        ],
      ]
    : [];

  let videoAsMainPhoto: DOMNode | undefined;
  const getGallery = (content: string) => {
    let res = content;
    let div = document.createElement('div');
    div.innerHTML = res;
    let gallery = div.querySelector('div.gallery');
    while (
      gallery?.parentElement &&
      gallery?.parentElement.tagName !== 'p' &&
      gallery?.parentElement.tagName !== 'div'
    ) {
      gallery = gallery.parentElement;
    }
    return div.innerHTML;
  };

  content.current = getGallery(content.current);

  const recommendedArticles = recommendedData.filter((item) => {
    if (item.lead === '' || item.id === data.id) {
      return false;
    }
    return true;
  });

  const addAds = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const paragraphs = doc.querySelectorAll('p');

    let adCounter = 1;
    let insertedAds = 0;

    for (let i = 0; i < paragraphs.length; i++) {
      const p = paragraphs[i];

      if (
        p.nextElementSibling &&
        p.nextElementSibling.classList.contains('line')
      ) {
        break;
      }

      if ((insertedAds + 1) % 4 === 0) {
        const adDiv = document.createElement('div');
        adDiv.className = `WTG_ARTICLE_${adCounter}`;

        p.insertAdjacentElement('afterend', adDiv);
        adCounter++;
      }

      insertedAds++;
    }

    return doc.body.innerHTML;
  };

  content.current = addAds(content.current);

  return (
    <>
      {content && (
        <>
          <Container fluid md>
            <div id="WTG_ARTICLE_TOP" className="WTG_ARTICLE_TOP" />
            {articleBanner2.customFields && (
              <ArticleTopBanner
                isMobile={isMobile}
                customFields={articleBanner2.customFields}
              />
            )}
            <Wrapper>
              <Box
                display="flex"
                justifyContent="center"
                alignItems={{ tablet: 'center', mobile: 'flex-start' }}
                flexDirection="column"
                pt={isMobile ? 3 : 'initial'}
              >
                <Box
                  maxWidth={{ tablet: '80%', mobile: '100%' }}
                  my="auto"
                  display="flex"
                  gridGap={1}
                  alignItems="center"
                  justifyContent="center"
                  mb={{ tablet: 3, mobile: 2 }}
                >
                  {isMobile ? (
                    <>
                      <Typography variant="breadcrumbsMobileArticle">
                        <Link
                          href="/"
                          target="_self"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          JEDYNKA
                        </Link>
                      </Typography>
                      <Typography color="main.primary" variant="caption2">
                        |
                      </Typography>
                      <Typography variant="breadcrumbsMobileArticle">
                        <Link
                          href={`/artykuly/${data.categoryId}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {data?.categoryName?.toUpperCase()}
                        </Link>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="caption2">
                        <Link
                          href="/"
                          target="_self"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          JEDYNKA
                        </Link>
                      </Typography>
                      <Typography color="main.primary" variant="caption2">
                        |
                      </Typography>
                      <Typography variant="caption2">
                        <Link
                          href={`/artykuly/${data.categoryId}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {data?.categoryName?.toUpperCase()}
                        </Link>
                      </Typography>
                    </>
                  )}
                </Box>
                <Box
                  maxWidth={{ tablet: '80%', mobile: '100%' }}
                  my="auto"
                  mb={{ tablet: 4, mobile: 1 }}
                >
                  <Typography
                    variant={isMobile ? 'header1' : 'showDetailsTitle'}
                    textAlign={{ tablet: 'center', mobile: 'left' }}
                    as="h1"
                  >
                    {data.title}
                  </Typography>
                </Box>
                <Box width="100%" display="flex" justifyContent="space-between">
                  {data.datePublic && !isMobile ? (
                    <Box
                      maxWidth={{ tablet: '80%', mobile: '100%' }}
                      my="auto"
                      mb={{ tablet: 4, mobile: 1 }}
                    >
                      <Typography variant="scheduleAudition">
                        Data publikacji:{' '}
                        {formatToDateTimeString(data.datePublic)}
                      </Typography>
                    </Box>
                  ) : null}
                  {data.lastModificationDate && !isMobile ? (
                    <Box
                      maxWidth={{ tablet: '80%', mobile: '100%' }}
                      my="auto"
                      mb={{ tablet: 4, mobile: 2 }}
                    >
                      <Typography variant="scheduleAudition">
                        Ostatnia aktualizacja:{' '}
                        {formatToDateTimeString(data.lastModificationDate)}
                      </Typography>
                    </Box>
                  ) : null}
                  {data.lastModificationDate && isMobile ? (
                    <Box
                      maxWidth={{ tablet: '80%', mobile: '100%' }}
                      my="auto"
                      mb={{ tablet: 4, mobile: 2 }}
                    >
                      <Typography variant="detailsSmall">
                        Ostatnia aktualizacja:{' '}
                        {formatToDateTimeString(data.lastModificationDate)}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box
                borderTop="1px solid"
                borderBottom="1px solid"
                py={2}
                borderColor="neutral.gray"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  {data.datePublic && isMobile ? (
                    <Box
                      maxWidth={{ tablet: '80%', mobile: '100%' }}
                      my="auto"
                      mb={1}
                    >
                      <Typography variant="detailsSmall">
                        Data&nbsp;publikacji:&nbsp;
                        {formatToDateTimeString(data.datePublic)}
                      </Typography>
                    </Box>
                  ) : null}
                  <ReadingTime article={data} />
                </Box>
                <ShareLinks link={`artykul/${data.id}`} />
              </Box>
              <Box py={{ tablet: 4, mobile: 2 }}>
                <Row>
                  <Col xl={9}>
                    <Box zIndex={900} overflow="hidden">
                      {!data.hideMainPhoto && !data.showVideoAsMainPhoto ? (
                        <Box mb={{ tablet: 4, mobile: 3 }}>
                          {attachments.map((el) => {
                            return el.map(
                              (
                                { fileType, file, description, author },
                                index
                              ) => {
                                if (
                                  data.showVideoAsMainPhoto &&
                                  videoAsMainPhoto
                                ) {
                                  return (
                                    <Box
                                      display={{
                                        desktop: 'block',
                                        mobile: 'flex',
                                      }}
                                      my={2}
                                      width="100%"
                                      flexDirection="column"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      {domToReact([videoAsMainPhoto])}
                                    </Box>
                                  );
                                }
                                if (
                                  fileType === 'Audio' &&
                                  file &&
                                  data.showAttachments
                                ) {
                                  return (
                                    <Box
                                      display={{
                                        desktop: 'block',
                                        mobile: 'flex',
                                      }}
                                      my={2}
                                      flexDirection="column"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <ArticlePlayer
                                        data={[
                                          {
                                            src: file,
                                            title: description,
                                          },
                                        ]}
                                        hasPlaylist={false}
                                      />
                                    </Box>
                                  );
                                }
                                if (
                                  fileType === 'Image' &&
                                  file &&
                                  index === 0 &&
                                  !data.showVideoAsMainPhoto &&
                                  !data.hideMainPhoto
                                ) {
                                  return (
                                    <Box
                                      display={{
                                        desktop: 'block',
                                        mobile: 'flex',
                                      }}
                                      my={2}
                                      flexDirection="column"
                                      alignItems={{
                                        tablet: 'center',
                                        mobile: 'flex-start',
                                      }}
                                      justifyContent="center"
                                    >
                                      <Box mb={2}>
                                        <img
                                          src={
                                            file
                                              ? file.replace(
                                                  'format=700',
                                                  'format=900'
                                                )
                                              : ''
                                          }
                                          alt={description}
                                          width="100%"
                                        />
                                      </Box>
                                      {isMobile ? (
                                        <Typography
                                          variant="detailsSmall"
                                          color="neutral.dark"
                                        >
                                          {description || null}
                                          {author
                                            ? `, Autor - ${author}`
                                            : null}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="body2"
                                          color="neutral.dark"
                                        >
                                          {description || null}
                                          {author
                                            ? `, Autor - ${author}`
                                            : null}
                                        </Typography>
                                      )}
                                    </Box>
                                  );
                                }
                                if (
                                  data.showVideoAsMainPhoto &&
                                  videoAsMainPhoto
                                ) {
                                  return (
                                    <Box
                                      display={{
                                        desktop: 'block',
                                        mobile: 'flex',
                                      }}
                                      my={2}
                                      width="100%"
                                      flexDirection="column"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      {domToReact([videoAsMainPhoto])}
                                    </Box>
                                  );
                                }
                                return null;
                              }
                            );
                          })}
                        </Box>
                      ) : null}
                      <Box mb={{ tablet: 4, mobile: 3 }}>
                        {isMobile ? (
                          <Typography variant="header1mobile">
                            {data.lead}
                          </Typography>
                        ) : (
                          <Typography variant="lead4">{data.lead}</Typography>
                        )}
                      </Box>
                      {!isHTML(content.current) ? (
                        <Typography variant="lead3">
                          {content.current}
                        </Typography>
                      ) : (
                        parse(content.current, {
                          replace: (domNode) => {
                            if (domNode) {
                              // @ts-ignore
                              const dom: Element = domNode;
                              const { attribs, children, name } = dom;
                              if (!attribs) {
                                return null;
                              }
                              if (attribs?.id?.includes('relacja-archiwum')) {
                                return (
                                  <LiveTicker
                                    id={attribs.id.split('-').slice(-1)[0]}
                                  />
                                );
                              }
                              if (
                                attribs.class === 'articleQuiz' &&
                                quizId &&
                                quizQuestion
                              ) {
                                return (
                                  <Quiz
                                    quizId={quizId}
                                    question={quizQuestion}
                                  />
                                );
                              }
                              if (
                                attribs.class === 'gallery' ||
                                attribs.class === 'insertedgallery'
                              ) {
                                return (
                                  <ArticleGallery
                                    images={galleryData?.attachments}
                                    title={galleryData.title}
                                  />
                                );
                              }

                              if (
                                attribs.class === 'video' &&
                                data.showVideoAsMainPhoto
                              ) {
                                videoAsMainPhoto = domNode;
                              }
                              if (
                                attribs.class?.includes(
                                  'article-default-html'
                                ) &&
                                // @ts-ignore
                                children.filter((el: Element) =>
                                  el?.attribs?.href?.includes('Galeria')
                                ).length &&
                                galleryData
                              ) {
                                return null;
                              }
                              if (
                                name === 'blockquote' &&
                                attribs.class !== 'twitter-tweet'
                              ) {
                                const flatChildren = children.map((el) => {
                                  // @ts-ignore
                                  return el?.children?.flat(1)[0]
                                    ? // @ts-ignore
                                      el?.children?.flat(1)[0]
                                    : el;
                                });
                                return (
                                  <ArticleQuote
                                    content={domToReact(flatChildren) as string}
                                  />
                                );
                              }
                              if (
                                attribs.class === 'video' &&
                                !data.showVideoAsMainPhoto
                              ) {
                                return (
                                  <Box my={3} mx="auto">
                                    {domToReact(children)}
                                  </Box>
                                );
                              }
                              if (
                                attribs.class?.includes('audio-default-html')
                              ) {
                                const positioner = children?.filter(
                                  // @ts-ignore
                                  (el: Element) => {
                                    return el.attribs?.class === 'positioner';
                                  }
                                )[0];
                                // @ts-ignore
                                const mediaPlay = positioner?.children?.filter(
                                  (el: Element) => {
                                    return el.attribs?.class?.includes(
                                      'pr-media-play'
                                    );
                                  }
                                )[0];
                                const dataBeforeParse =
                                  mediaPlay?.attribs['data-media'];
                                if (!dataBeforeParse) {
                                  return null;
                                }
                                const data = JSON.parse(dataBeforeParse);
                                if (
                                  // @ts-ignore
                                  attachments &&
                                  attachments[1]?.filter((e) => {
                                    return e?.fileName === data?.file;
                                  })?.length > 0
                                ) {
                                  return null;
                                }
                                const time = data.length;
                                let audioData: {
                                  src: string;
                                  title: string;
                                  time: string;
                                } = {
                                  src: data?.file,
                                  title: decodeURIComponent(data.desc),
                                  time,
                                };
                                return (
                                  <ArticlePlayer
                                    data={[audioData]}
                                    hasPlaylist={data.hasPlaylist}
                                  />
                                );
                              }
                              if (
                                attribs.class?.includes(
                                  'article-default-html'
                                ) &&
                                // @ts-ignore
                                !children.filter((el: Element) =>
                                  el?.attribs?.href?.includes('Galeria')
                                ).length
                              ) {
                                return (
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    gridGap={2}
                                    my={2}
                                    maxWidth="90vw"
                                  >
                                    <Typography variant="lead2">
                                      {domToReact(children)}
                                    </Typography>
                                  </Box>
                                );
                              }
                              if (
                                name === 'strong' &&
                                // @ts-ignoreF
                                dom?.parent?.name === 'p'
                              ) {
                                if (dom?.next) {
                                  return;
                                }
                                if (children.length) {
                                  // @ts-ignore
                                  if (children[0]?.data) {
                                    return (
                                      <Typography as="h3" variant="h3">
                                        {
                                          // @ts-ignore
                                          children[0]?.data
                                        }
                                      </Typography>
                                    );
                                  }
                                }
                              }
                              if (
                                attribs.class?.includes('image-default-html') &&
                                attribs.class?.includes('image')
                              ) {
                                return (
                                  <Box
                                    display={{
                                      desktop: 'flex',
                                      mobile: 'block',
                                    }}
                                    my={3}
                                    width="100%"
                                    height="auto"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    {domToReact([domNode])}
                                  </Box>
                                );
                              }
                              if (name === 'ul' || name === 'ol') {
                                return (
                                  <Box my={3}>{domToReact([domNode])}</Box>
                                );
                              }
                              if (
                                name === 'blockquote' &&
                                attribs.class === 'twitter-tweet'
                              ) {
                                const twittContentStatus =
                                  // @ts-ignore
                                  domNode?.children[
                                    // @ts-ignore
                                    domNode.children.length - 1
                                  ]?.attribs?.href.match(/\/status\/[0-9]*/)[0];
                                const twittId = twittContentStatus?.substring(
                                  twittContentStatus.lastIndexOf('/') + 1
                                );

                                return (
                                  <Box my={3} mx="auto" className="tweet-box">
                                    <Tweet
                                      tweetId={twittId}
                                      onLoad={() => {
                                        Array.from(
                                          document.getElementsByClassName(
                                            'tweet-box'
                                          ) as HTMLCollectionOf<HTMLElement>
                                        )?.forEach((el) => {
                                          // eslint-disable-next-line no-param-reassign
                                          el.style.width = 'max-content';
                                        });
                                      }}
                                    />
                                  </Box>
                                );
                              }
                              if (name === 'p') {
                                let isIframe = false;
                                // eslint-disable-next-line no-return-assign
                                dom?.children.forEach((el) =>
                                  // @ts-ignore
                                  el?.name === 'iframe'
                                    ? (isIframe = true)
                                    : (isIframe = false)
                                );
                                return isIframe ? (
                                  <Box my={3} mx="auto" width="max-content">
                                    {domToReact(children)}
                                  </Box>
                                ) : (
                                  <Typography variant="lead3">
                                    {domToReact([domNode])}
                                  </Typography>
                                );
                              }
                              if (name === 'strong') {
                                return (
                                  <Typography variant="h3">
                                    {domToReact([domNode])}
                                  </Typography>
                                );
                              }
                            }
                          },
                        })
                      )}
                      {playlistData.length !== 0 &&
                        data.hasPlaylist &&
                        attachments.map((el) => {
                          return el.map(({ fileType, file, description }) => {
                            if (fileType === 'Audio' && !data.hasAudio) {
                              return (
                                <Box
                                  display={{
                                    desktop: 'block',
                                    mobile: 'flex',
                                  }}
                                  my={2}
                                  flexDirection="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  key={file}
                                >
                                  <ArticlePlayer
                                    data={[
                                      {
                                        src: file,
                                        title: description,
                                      },
                                    ]}
                                    hasPlaylist={false}
                                  />
                                </Box>
                              );
                            }
                            return null;
                          });
                        })}
                      {playlistData.length ? (
                        <Box my={3}>
                          {/* <ArticlePlaylist playlistData={playlistData} /> */}
                        </Box>
                      ) : null}
                      {data?.subjectTags ? (
                        <Box
                          borderTop="1px solid"
                          borderTopColor="neutral.gray"
                          my={3}
                          display="flex"
                          gridGap={3}
                          flexWrap="wrap"
                          width="100%"
                          py={3}
                        >
                          {data.subjectTags.map((el) => {
                            return (
                              <Box marginRight={2} marginBottom={2}>
                                <ArticleTag
                                  id={el.id}
                                  name={el.name}
                                  key={el.id}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      ) : null}
                    </Box>
                  </Col>
                  {!isMobile ? (
                    <Col xl={3}>
                      <Box
                        zIndex={1000}
                        backgroundColor="neutral.white"
                        marginTop={2}
                      >
                        <ReadAlsoSide
                          articlesData={articlesData}
                          bannerData={articleBanner}
                        />
                      </Box>
                    </Col>
                  ) : null}
                </Row>
              </Box>
            </Wrapper>
          </Container>
          {recommendedArticles.length > 0 ? (
            <Box
              backgroundColor="#f2f2f2"
              p={isMobile ? 0 : 4}
              pt={isMobile ? 3 : undefined}
            >
              <Container>
                <Row>
                  <Col xl={12}>
                    <ReadAlsoSideRecommended
                      recommendedvariant
                      // @ts-ignore
                      articlesData={recommendedArticles}
                    />
                  </Col>
                </Row>
                <div id="WTG_BOTTOM" className="WTG_BOTTOM" />
              </Container>
            </Box>
          ) : (
            <Container>
              <div id="WTG_BOTTOM" className="WTG_BOTTOM" />
            </Container>
          )}
        </>
      )}
    </>
  );
};
