import {
  AvatarBox,
  Box,
  LetterFilter,
  Link,
  PaginatedList,
  SectionTitle,
} from 'components';
import React from 'react';
import { useRouter } from 'next/router';
import { Container } from 'react-grid-system';
import { PageProps, TeamMember } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { removeDiacritics } from 'utils/removeDiacritics';

const convertNameToInitial = (name: string) => {
  return name[0].toLowerCase().normalize('NFD');
};

export const ZespolDesktop: React.FC<PageProps<TeamMember[]>> = ({
  data: team,
}) => {
  const router = useRouter();
  const letter: string | string[] = router.query.filter
    ? router.query.filter
    : '';

  const { isMobile, isTablet } = React.useContext(MobileContext);
  const [filteredTeam, setFilteredTeam] = React.useState<TeamMember[]>(
    team.filter(
      (member) =>
        removeDiacritics(member.surname[0].toLowerCase()) ===
        (letter as string)?.toLowerCase()
    )
  );
  const [pageCount, setPageCount] = React.useState<number>(
    Math.ceil(filteredTeam.length / 20)
  );
  const [startIndex, setStartIndex] = React.useState<number>(0);
  const [endIndex, setEndIndex] = React.useState<number>(20);

  const activeLetters = team
    ?.map((teamMember) => convertNameToInitial(teamMember.surname || 'a'))
    .filter((letter, index, self) => self.indexOf(letter) === index);

  const getColumns = React.useCallback(() => {
    if (isMobile) {
      return '1fr';
    }
    if (isTablet) {
      return '1fr 1fr 1fr';
    }

    return '1fr 1fr 1fr 1fr 1fr';
  }, [isMobile, isTablet]);

  React.useEffect(() => {
    handleSetPage(1);
    if (letter) {
      setFilteredTeam(
        team.filter(
          (member) =>
            removeDiacritics(member.surname[0].toLowerCase()) ===
            (letter as string)?.toLowerCase()
        )
      );
    } else {
      setFilteredTeam(team);
    }
  }, [letter]);

  React.useEffect(() => {
    setPageCount(Math.ceil(filteredTeam.length / 20));
  }, [filteredTeam]);

  const pageNum = router.query.p || 1;

  const handleSetPage = (val: number) => {
    if (val >= 1 && val <= pageCount) {
      router.push(
        {
          query: {
            ...router.query,
            p: val,
          },
        },
        undefined,
        { scroll: false }
      );
    }
  };

  React.useEffect(() => {
    if (pageNum >= 1 && pageNum <= pageCount) {
      setStartIndex((+pageNum - 1) * 20);
      setEndIndex((+pageNum - 1) * 20 + 20);
    } else {
      if (pageNum < 1) return handleSetPage(1);
      if (pageNum > pageCount) return handleSetPage(pageCount);
    }
  }, [pageNum]);

  return (
    <Box py={4} mt={isMobile ? 5 : 0} as="article">
      <Container fluid md>
        <SectionTitle
          borderColor="main.primary"
          borderWidth={5}
          shadow={!isMobile}
          shadowOffset={4}
        >
          Zespół
        </SectionTitle>
        <Box py={3}>
          <LetterFilter activeLetters={activeLetters} />
        </Box>
        <PaginatedList
          page={+pageNum}
          pageCount={pageCount}
          loading={false}
          gridTemplateColumns={getColumns()}
          items={
            filteredTeam
              .slice(startIndex, endIndex)
              ?.map((member: TeamMember) => (
                <Link href={`/zespol/${member.articleId}`} key={member.id}>
                  <AvatarBox
                    src={`https://static.prsa.pl/images/${member?.fileUid}.jpg?format=700`}
                    caption={`${member.name} ${member.surname}`}
                    size="200px"
                    centered
                  />
                </Link>
              )) || []
          }
          onNext={() => handleSetPage(+pageNum + 1)}
          onPrev={() => handleSetPage(+pageNum - 1)}
          onChange={(val: number) => handleSetPage(val)}
        />
      </Container>
    </Box>
  );
};
